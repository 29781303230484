import PropTypes from 'prop-types'
import React from 'react'
import classNames from 'classnames'
import VM from 'scratch-vm'

import Box from '../box/box.jsx'
import { STAGE_DISPLAY_SIZES } from '../../lib/layout-constants.js'
import StageHeader from '../../containers/stage-header.jsx'
import Stage from '../../containers/stage.jsx'
import Loader from '../loader/loader.jsx'

import styles from './stage-wrapper.css'

const StageWrapperComponent = function (props) {
    const {
        isFullScreen,
        menu,
        mobile,
        isRtl,
        isRendererSupported,
        loading,
        stageSize,
        vm,
    } = props

    return (
        <div>
            <Box
                className={classNames(styles.stageWrapper, {
                    [styles.fullScreen]: isFullScreen,
                })}
                dir={isRtl ? 'ltr' : 'ltr'}
            >
                {menu ? (
                    <Box
                        className={styles.stageMenuWrapper}
                        style={
                            [
                                '?pc1.0',
                                '?zing0.1',
                                '?zing1.0',
                                '?zing1.0s3',
                                '?roboticArm',
                                '?hexapod',
                                '?roboki',
                                '?hexapod1.0s3',
                                '?atr',
                            ].includes(window.location.search)
                                ? {
                                      transform: 'translateX(-1270%)',
                                  }
                                : null
                        }
                    >
                        <StageHeader stageSize={stageSize} vm={vm} />
                    </Box>
                ) : null}

                <Box
                    className={
                        [
                            '?pc1.0',
                            '?zing0.1',
                            '?zing1.0',
                            '?zing1.0s3',
                            '?roboticArm',
                            '?hexapod',
                            '?roboki',
                            '?hexapod1.0s3',
                            '?atr',
                        ].includes(window.location.search)
                            ? styles.stageCanvasWrappers
                            : styles.stageCanvasWrapper
                    }
                >
                    {isRendererSupported ? (
                        <Stage stageSize={stageSize} vm={vm} id={'simstage'} />
                    ) : null}
                </Box>
                <br></br>
                {loading ? <Loader isFullScreen={isFullScreen} /> : null}
            </Box>
            <Box
                className={classNames(styles.stageWrapper, {
                    [styles.fullScreen]: isFullScreen,
                })}
                dir={isRtl ? 'ltr' : 'ltr'}
            >
                <Box
                    className={
                        [
                            '?pc1.0',
                            '?zing0.1',
                            '?zing1.0',
                            '?zing1.0s3',
                            '?roboticArm',
                            '?hexapod',
                            '?roboki',
                            '?hexapod1.0s3',
                            '?atr',
                        ].includes(window.location.search)
                            ? styles.stageCanvasWrappers
                            : styles.stageCanvasWrapper
                    }
                >
                    {isRendererSupported ? (
                        <Stage
                            stageSize={stageSize}
                            vm={vm}
                            id={'pyCodeEditor'}
                        />
                    ) : null}
                </Box>

                {loading ? <Loader isFullScreen={isFullScreen} /> : null}
            </Box>
        </div>
    )
}

StageWrapperComponent.propTypes = {
    isFullScreen: PropTypes.bool,
    menu: PropTypes.bool.isRequired,
    isRendererSupported: PropTypes.bool.isRequired,
    isRtl: PropTypes.bool.isRequired,
    loading: PropTypes.bool,
    stageSize: PropTypes.oneOf(Object.keys(STAGE_DISPLAY_SIZES)).isRequired,
    vm: PropTypes.instanceOf(VM).isRequired,
}

export default StageWrapperComponent
